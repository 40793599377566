
import { defineComponent } from "vue";

export default defineComponent({
	name: 'PageHeader',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
})


import { mapActions, mapState } from "pinia"
import { defineComponent } from "vue"
import { VueRouterInst } from "../InitRoutesPlugins"
import { useStore, Validators } from "../store/PiniaStore"

export default defineComponent({
	components: {

	},
	props: {
	},
	computed: {
		...mapState(useStore, ["userManagement", "isLoggedIn"]),
	},
	methods: {
		...mapActions(useStore, ["submitLogin", "getKeyValueRecords"]),

		isEmailAddress(emailAddress: string): boolean {
			return Validators.isEmailAddress(emailAddress)
		},

		async tryLogin() {
			if (!Validators.isEmailAddress(this.userManagement.emailAddress)) {
				return
			}

			const result = await this.submitLogin()

			if (!result) {
				this.$buefy.toast.open({
					duration: 6000,
					message: 'Invalid login, please try again.',
					type: 'is-danger',
				})
				this.userManagement.password = ''
			} else {
				await this.getKeyValueRecords()
				VueRouterInst.push({ path: '/bulk-search' })
			}
		},

	},

	mounted(): void {
		console.log(`LoginScreen mounted`)

		if (this.isLoggedIn) {
			VueRouterInst.push({ path: '/bulk-search', replace: true })
		}
	},
})

import { invert, isNil, last, omitBy, remove, uniqBy } from "lodash"
import { defineStore } from "pinia"
import { INewUser, ProspectSearchCriteria, BULK_SEARCH_HEADER_CANDIDATES, PROSPECT_SEARCH_RESULTS_GRID_COLUMNS, BULK_SEARCH_COLORS, FABActionType, ProspectSearchFields } from "@/lib/InterfacesAndTypes"
import { applySearchExclusionRules, findIndexOfFirstIncludedValue, isStringDictionaryEmptyOrWhitespace, setPageTitle } from "@/lib/HelperFns"
import axios, { AxiosError } from "axios"
import { VueRouterInst } from "@/InitRoutesPlugins"
import { saveAs } from "file-saver"

export const Validators = {
	isEmailAddress(email: string): boolean {
		const regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i
		return regex.test(email)
	},
}

export const useStore = defineStore('main', {
	state: () => ({
		searchesActive: [] as ProspectSearchCriteria[],
		bulkProspectSearchCriteria: [] as ProspectSearchCriteria[],
		bulkProspectSearchProgress: 0,
		userAccounts: [],
		dataAuditRecords: [],

		FABSelectedReportRows: JSON.parse(getValidLocalStorageKey("FABSelectedReportRows") ?? "[]"),
		FABSelectedReportRowCount: 0,
		FABActionType: getValidLocalStorageKey("FABActionType") ?? null as FABActionType,
		FABShowCancel: false,

		userManagement: {
			emailAddress: '',
			password: '',
			name: '',
			isLoggingIn: false,
		},

		lookups: {
			countyByNumber: {},
			countyByName: {},
		},

		prospectSearchResultsGridColumns: PROSPECT_SEARCH_RESULTS_GRID_COLUMNS,
	}),
	getters: {
		mostRecentSearch: (state) => {
			return last(state.searchesActive)
		},

		queuedBulkProspectSearches: (state) => {
			return state.bulkProspectSearchCriteria
		},

		isLoggedIn: (state) => {
			return state.userManagement.name || false
		},

		FABSelectedReportRowsAsSearchGrid: (state) => {
			return {
				results: state.FABSelectedReportRows,
			}
		},
	},
	actions: {
		validateIsEmailAddress(email: string) {
			return Validators.isEmailAddress(email)
		},

		addEmptySearch() {
			this.searchesActive.push(new ProspectSearchCriteria())
			setPageTitle(`${this.searchesActive.length} Prospect Searches`)
			this.updateSearchTitle()
		},

		addSearch(search: ProspectSearchCriteria) {
			this.searchesActive.push(search)
			this.updateSearchTitle()
		},

		closeSearch(search: ProspectSearchCriteria) {
			this.searchesActive.remove(search)
			this.updateSearchTitle()
		},

		closeSearchByIndex(i: number) {
			this.searchesActive.splice(i, 1)
			this.updateSearchTitle()
		},

		updateSearchTitle() {
			setPageTitle(`${this.searchesActive.length} Prospect Searches`)
		},

		setFABAction(actionType: FABActionType) {
			this.FABActionType = actionType
			if (actionType === "generate-report") {
				this.FABSelectedReportRows = []
			}
			if (actionType === null) {
				this.FABSelectedReportRows = []
				this.FABShowCancel = false
			}
			localStorage.setItem("FABActionType", actionType as string)
			localStorage.setItem("FABSelectedReportRows", JSON.stringify(this.FABSelectedReportRows, null, 2))
		},

		setFABShowCancel(show: boolean) {
			this.FABShowCancel = show
		},

		addReportRow(row: any, rowSearchContext: ProspectSearchFields) {
			if (this.FABActionType !== "generate-report") {
				this.setFABAction("generate-report")
			}

			const combinedRows: any[] = []
			if (this.FABSelectedReportRows?.length) {
				combinedRows.push(...this.FABSelectedReportRows)
			}
			row.__searchContext = Object.assign({}, rowSearchContext)
			combinedRows.push(row)
			// console.log({row})
			const uniqueRows = uniqBy(combinedRows, 'DOCKET')
			// console.log('addReportRow', uniqueRows)
			if (uniqueRows.length === 0) {
				return this.clearFABAction()
			}
			this.FABSelectedReportRows = uniqueRows
			this.FABSelectedReportRowCount = uniqueRows.length
			localStorage.setItem("FABSelectedReportRows", JSON.stringify(this.FABSelectedReportRows, null, 2))
		},

		removeReportRow(row: any) {
			if (this.FABActionType !== "generate-report") {
				this.setFABAction("generate-report")
			}

			remove(this.FABSelectedReportRows, (value: any) => {
				// console.log(`value.DOCKET (${value.DOCKET}) === row.DOCKET (${row.DOCKET})`, {
				// 	value, row,
				// })
				return value.DOCKET === row.DOCKET && value.ACTION_SEQ === row.ACTION_SEQ
			})
			this.FABSelectedReportRowCount = this.FABSelectedReportRows.length
			// console.log('removeReportRow', this.FABSelectedReportRows, this.FABSelectedReportRows.length, this.FABSelectedReportRowCount)
			localStorage.setItem("FABSelectedReportRows", JSON.stringify(this.FABSelectedReportRows, null, 2))
		},

		async doFABAction() {
			// console.log({
			// 	actionType: this.FABActionType,
			// 	selectedRows: this.FABSelectedReportRows,
			// })
			if (this.FABActionType === "generate-report") {
				if (VueRouterInst.currentRoute.path === "/review-report") {
					await this.generateAndDownloadReport()
				} else {
					VueRouterInst.push({ path: '/review-report' })
				}
			}
		},

		async generateAndDownloadReport() {
			console.group('generateAndDownloadReport')
			const url = '/api/generate-report'
			const body = {
				selectedReportRows: this.FABSelectedReportRows,
			}
			console.log({body})
			try {
				const result = await axios.post(url, body, {
					responseType: 'blob',
					headers: {
						'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
					},
					insecureHTTPParser: true,
				})
				const blob = new Blob([result.data], {
					type: result.headers['Content-Type'],
				})
				console.log(result.headers)
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.setAttribute('download', result.headers['x-wm-filename'] ?? 'REPORT.docx')
				link.click()
				console.groupEnd()
				return result
			} catch (err) {
				console.error('Generate report failed', err)
				console.groupEnd()
			}

		},

		clearFABAction() {
			this.FABActionType = null
			this.FABSelectedReportRows = []
			this.FABSelectedReportRowCount = 0
			this.FABShowCancel = false
			localStorage.removeItem("FABActionType")
			localStorage.removeItem("FABSelectedReportRows")
			if (VueRouterInst.currentRoute.path === "/review-report") {
				VueRouterInst.push({ path: '/search' })
			}
		},

		downloadCSVBulkTemplate() {
			console.log(`downloadCSVTemplate()`)
			const csvHeaderRow = 'First Name,Middle Name,Last Name,Date of Birth,Court Code,Location Code,Division,Case ID'
			const csvFile = new File([csvHeaderRow], 'BulkProspectSearchCriteria.csv', { type: 'text/csv;charset=utf-8' });
			saveAs(csvFile)
		},

		parseCSVBulkFile(rawText: string, allowCommas = false) {
			console.group('parseCSVBulkFile')
			console.log(`parseCSVBulkFile()`, rawText)
			this.bulkProspectSearchCriteria = []

			if (!rawText) {
				// TODO (KJE, 6/1/2023): Better UX when doing UX for Bulk Search Page
				console.log('received null or undefined input to parseCSVBulkFile() method. What went wrong?')
				return
			}

			const lines = rawText.split('\r\n')

			if (!lines || lines.length === 0) {
				// TODO (KJE, 6/1/2023): Better UX when doing UX for Bulk Search Page
				console.log('received blank CSV file in parseCSVBulkFile().  Clearing criteria.')
				return
				
			}


			const firstLine = lines.first()
			const firstLineValues = firstLine.split(',')
			for (let count = 0; count < firstLineValues.length; count++) {
				firstLineValues[count] = firstLineValues[count].toLowerCase()
			}

			const firstNameIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.firstName)
			const lastNameIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.lastName)
			const middleNameIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.middleName)
			const dobIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.dateOfBirth)
			const courtCodeIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.courtCode)
			const locationCodeIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.locationCode)
			const divisionIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.division)
			const caseIdIndex = findIndexOfFirstIncludedValue(firstLineValues, BULK_SEARCH_HEADER_CANDIDATES.caseId)

			// Remove header row
			lines.splice(0, 1)

			if (lines.length === 0) {
				// TODO (KJE, 6/1/2023): Better UX when doing UX for Bulk Search Page
				console.log('received blank CSV file in parseCSVBulkFile().  Clearing criteria.')
				return
			}

			lines.forEach((line: string) => {
				// NOTE: this assumes CSV files will place any cell data containing a comma inside of ""s
				//       Unless the CSV is built erroneously by hand, this really SHOULD be true.

				// Deal with cells ending in ", AR" by removing the ", AR"
				if (line.match(/, *AR/)) {
					line = line.replace(/\"(.+,) *AR *\"/, "\"$1\"")
				}

				const escapedLine = this.escapeEmbeddedCommasInCSV(line)
				const incFields = escapedLine.split(',') as string[]
				const unescapedFields: string[] = []

				incFields.forEach((field:string) => {
					if (!!field && allowCommas) {
						unescapedFields.push(field.replaceAll("&#44;", ","))
					} else if (!!field && !allowCommas) {
						unescapedFields.push(field.replaceAll("&#44;", ""))
					} else {
						unescapedFields.push("")
					}
				})

				const prospect = new ProspectSearchCriteria()
				prospect.fields = {
					firstName: firstNameIndex === -1 ? '' : unescapedFields[firstNameIndex],
					middleName: middleNameIndex === -1 ? '' : unescapedFields[middleNameIndex],
					lastName: lastNameIndex === -1 ? '' : unescapedFields[lastNameIndex],
					dateOfBirth: dobIndex === -1 ? '' : unescapedFields[dobIndex],
					dateOfBirthNullCheck: false,
					courtCode: courtCodeIndex === -1 ? '' : unescapedFields[courtCodeIndex],
					locationCode: locationCodeIndex === -1 ? '' : unescapedFields[locationCodeIndex],
					division: divisionIndex === -1 ? '' : unescapedFields[divisionIndex],
					caseId: caseIdIndex === -1 ? '' : unescapedFields[caseIdIndex],
				}
				if (isStringDictionaryEmptyOrWhitespace(prospect.fields)) {
					// TODO (KJE, 6/13/2023): Better UX when doing UX for Bulk Search Page
					console.log('Empty prospect found. Skipping prospect.', line)
				} else {
					this.bulkProspectSearchCriteria.push(prospect)
				}
			})
			setPageTitle(`${lines.length} Bulk Searches`)
			console.groupEnd()
		},

		escapeEmbeddedCommasInCSV(line: string) {
			// Deal with cells that include one or more commas in their data
			// 1) Add filler | on either side of string to avoid first/last cell shenanigans
			// 2) Split line by "
			// 	2a) NOTE: a sheet that has a cell containing a single " value will disrupt this logic for that line
			// 3) For each EVEN cell, replace the commas with HTML Escape (&#44;)
			// 4) Re-join cells
			// 5) Remove | filler text
			// 6) Replace HTML Escape with , when pushing cell to fields dictionary

			let escapedLine = line
			if (line.match(/"/)) {
				const lineForParsing = `|${line}|`
				const splitByQuotes = lineForParsing.split("\"")
				const escapedSplitByQuotes: string[] = []
				splitByQuotes.forEach((section:string, index:number) => {
					if (index % 2 === 0) {
						escapedSplitByQuotes.push(section)
					} else {
						escapedSplitByQuotes.push(section.replaceAll(",", "&#44;"))
					}
				})
				escapedLine = escapedSplitByQuotes.join("")
				escapedLine = escapedLine.substring(1, escapedLine.length - 1)
			}

			return escapedLine
		},

		async runEntireBulkProspectSearch() {
			console.groupCollapsed('runEntireBulkProspectSearch')

			//Number of threads allowed to search at once
			const increment = 10

			const setBulkColorFunction = ((index: number) => {
				const colorMe = this.bulkProspectSearchCriteria[index]
				if (colorMe.hasErrors) {
					colorMe.bulkSearchColor = BULK_SEARCH_COLORS['Error']
				} else if (colorMe.hasResults) {
					colorMe.bulkSearchColor = BULK_SEARCH_COLORS['Results']
				} else {
					colorMe.bulkSearchColor = BULK_SEARCH_COLORS['Empty']
				}
			})

			if (!this.bulkProspectSearchCriteria || !this.bulkProspectSearchCriteria.length) {
				console.error('No criteria to search against.')
				console.groupEnd()
			}

			for (let promisesKicked = 0; promisesKicked < increment; promisesKicked++) {
				if (this.bulkProspectSearchCriteria.length > promisesKicked) {
					this.runSingleSearchForBulkAndAttemptNext(promisesKicked, increment, setBulkColorFunction)
				} else {
					break
				}
			}

			console.groupEnd()
		},
		async runSingleSearchForBulkAndAttemptNext(index: number, increment: number, setBulkColorFunction: any) {
			console.log(`Running single search for Bulk, index ${index}`)

			await this.submitSearch(this.bulkProspectSearchCriteria[index], false)

			setBulkColorFunction(index)

			this.bulkProspectSearchProgress += 1

			if (this.bulkProspectSearchProgress < this.bulkProspectSearchCriteria.length) {
				index += increment
				if (this.bulkProspectSearchCriteria.length > index) {
					this.runSingleSearchForBulkAndAttemptNext(index, increment, setBulkColorFunction)
				}
			} else {
				this.initialSortBulkProspectSearchData()
			}
		},
		initialSortBulkProspectSearchData() {
			const columnSortCompareResults = (a, b) => {
				return a.results.length - b.results.length
			}

			this.bulkProspectSearchCriteria.sort(columnSortCompareResults)

			this.bulkProspectSearchCriteria.reverse()
		},

		async submitCreateNewUser(newUser: INewUser) {
			console.group('submitCreateNewUser')
			const url = '/api/user-create'
			const body = {
				name: newUser.name,
				emailAddress: newUser.emailAddress,
				password: newUser.password,
			}
			console.log({body})
			const result = await axios.post(url, body)
			if (result.status === 200) {
				await this.fetchUserAccounts()
				console.groupEnd()
				return true
			} else {
				console.error('Create new user failed', result)
				console.groupEnd()
				return false
			}
		},

		async changeUserPassword(emailAddress: string, newPassword: string) {
			console.group('changeUserPassword')
			const url = '/api/user-change-password'
			const body = {
				emailAddress: emailAddress,
				password: newPassword,
			}
			console.log({body})
			try {
				const result = await axios.post(url, body)
				console.groupEnd()
				return true
			} catch (err) {
				console.error('Change user password failed', err)
				console.groupEnd()
				return false
			}
		},

		async deleteUser(emailAddress: string) {
			console.group('deleteUser')
			const url = '/api/user-delete'
			const body = {
				emailAddress: emailAddress,
			}
			console.log({body})
			try {
				await axios.post(url, body)
				await this.fetchUserAccounts()
				console.groupEnd()
				return true
			} catch (err) {
				console.error('Delete user failed', err)
				console.groupEnd()
				return false
			}
		},

		async submitLogin() {
			console.group('Logging in...')
			this.userManagement.isLoggingIn = true
			const url = '/api/login'
			const body = {
				emailAddress: this.userManagement.emailAddress,
				password: this.userManagement.password,
			}
			try {
				const result = await axios.post(url, body)
				const user = result.data
				this.setCurrentUser(user)
				console.log(`Logged in as ${this.userManagement.name}`)
				this.userManagement.isLoggingIn = false
				console.groupEnd()
				return true
			} catch (err) {
				console.error('Login failed', err)
				this.userManagement.isLoggingIn = false
				console.groupEnd()
				return false
			}
		},

		async getLoggedInUserDetails() {
			const url = 'api/logged-in'
			const result = await axios.post(url)
			if (result.status === 200) {
				const user = result.data
				this.setCurrentUser(user)
				console.log(`Logged in user details:`, user)
			}
		},

		async getKeyValueRecords() {
			const url = 'api/key-value?keyId='
			const params = ['county_lookup']
			const promises = params.map((param) => {
				return axios.get(url + param)
			})
			const results = await Promise.all(promises)
			const [ countyLookup ] = results
			this.lookups.countyByNumber = countyLookup.data.value
			this.lookups.countyByName = invert(this.lookups.countyByNumber)
			for (const key in this.lookups.countyByName) {
				const value = this.lookups.countyByName[key]
				if (value.startsWith('0') && value.length > 2) {
					const trimmedValue = +value
					this.lookups.countyByName[key] = String(trimmedValue)
				}
			}
		},

		async fetchDataAuditRecords() {
			const url = 'api/audit-log?startDate=2023-03-01&endDate=2030-01-01&includeMetadata=true'
			const result = await axios.get(url)
			console.log({result})
			if (result.status === 200) {
				this.dataAuditRecords = result.data.map((record: any) => {
					return {
						...record,
						jobResults: `<pre>${JSON.stringify(record.jobResults, null, 2)}</pre>`,
						jobMetadata: JSON.stringify(record.jobMetadata, null, 2),
					}
				})
			}
		},

		async fetchUserAccounts() {
			const url = 'api/user-accounts'
			const result = await axios.get(url)
			console.log({result})
			if (result.status === 200) {
				this.userAccounts = result.data
			}
		},

		async submitLogout() {
			console.group(`Logging out of ${this.userManagement.name}`)
			this.clearCurrentUser()
			VueRouterInst.push({ path: '/login' })
			console.groupEnd()
		},

		setCurrentUser(user: any) {
			this.userManagement.name = user.name
			this.userManagement.emailAddress = user.emailAddress
			this.userManagement.password = ''
			localStorage.setItem('auth-token', user.authentication)
			axios.defaults.headers.common['auth-token'] = user.authentication
		},

		clearCurrentUser() {
			this.userManagement.emailAddress = ''
			this.userManagement.name = ''
			this.userManagement.password = ''
			localStorage.setItem('auth-token', '')
		},

		onSearchResultsTableCellClick(row, column, timeout, buefy) {
			const columnKey = column._props.field ?? column.newKey
			const now = Date.now()
			if (columnKey === 'DOCKET' || now <= timeout + 300) {
				const value = row[columnKey]
				navigator.clipboard.writeText(value)
				buefy.toast.open({
					duration: 3000,
					message: `Copied '${value}' to clipboard!`,
					type: 'is-success',
				})
			}
			return now
		},

		async submitSearch(search: ProspectSearchCriteria, logSearch: Boolean = true) {
			console.group(`Searching...`)
			const start = Date.now()
			search.fields.firstName = search.fields.firstName?.toUpperCase()
			search.fields.middleName = search.fields.middleName?.toUpperCase()
			search.fields.lastName = search.fields.lastName?.toUpperCase()
			search.fields.courtCode = search.fields.courtCode?.toUpperCase()
			console.groupCollapsed(`checking courtCode: ${search.fields.courtCode}`)
			if (search.fields.courtCode && !/[0-9]+/i.test(search.fields.courtCode)) {
				search.fields.courtCode = this.lookups.countyByName[search.fields.courtCode]
				console.log(`detected name, converting to number, now: ${search.fields.courtCode}`)
			}
			console.groupEnd()
			search.fields = omitBy(search.fields, isNil)
			for (const key in search.fields) {
				if (search.fields[key] == '') {
					delete search.fields[key]
				}
			}
			search.isSearching = true
			search.hasErrors = false
			if (logSearch) {
				console.log('Sanitized search: ', search)
			}
			// const params = new URLSearchParams(search.fields)
			const url = '/api/search-case-records?'
			if (logSearch) {
				console.log({url, fields: search.fields})
			}
			let data
			try {
				const results = await axios.get(url, {
					params: {
						...search.fields,
					},
				})
				data = results.data
			} catch (e) {
				const err = e as AxiosError
				console.error(err)
				search.isSearching = false
				search.hasErrors = {
					message: err.message,
					status: err.response?.status,
					statusText: err.response?.statusText,
				}
				return
			}
			data.forEach((record) => {
				console.log(record)
				record['AOC_LINK'] = `<a target="_blank" href="https://caseinfo.arcourts.gov/opad/case/${record.DOCKET}"><i class="mdi mdi-open-in-new mdi-18px"></i></a>`
			})
			console.log('Records returned:', data.length)
			const uniqueDocketKeys = [...new Set(data.map((item: any) => item.DOCKET))]
			const coalescedRecords:any[] = []		// Return value: most recent non-null, non-'' values, per field, per docket
			let index = 0

			uniqueDocketKeys.forEach((uniqueDocketKey: any) => {
				coalescedRecords.push({
					DOCKET: uniqueDocketKey,
				})

				const rowsForDocket: any[] = Object.values(
					data.filter((dataRow: any) => dataRow.DOCKET === uniqueDocketKey))

				// Sort the rows for the docket in descending order (most recent items first) by ACTION_SEQ
				rowsForDocket.sort((a: any, b: any) => {
					if (a.ACTION_SEQ < b.ACTION_SEQ) {
						return -1
					} else if (a.ACTION_SEQ > b.ACTION_SEQ) {
						return 1
					} else {
						return 0
					}
				})
				.reverse()

				coalescedRecords[index]['RAW_RECORDS'] = rowsForDocket

				// Grab the most recent non-null, non-'' value for each field for this Docket ID
				// Fill in any blanks with empty string
				Object.keys(rowsForDocket[0]).forEach((dataKey: any) => {
					const filteredRows: any[] = rowsForDocket.filter((row: any) => (row[dataKey] !== undefined && row[dataKey] !== null && row[dataKey] !== ''))

					if (filteredRows !== undefined && filteredRows !== null && filteredRows.length > 0) {
						coalescedRecords[index][dataKey] = filteredRows[0][dataKey]
					} else {
						coalescedRecords[index][dataKey] = ''
					}
				})


				rowsForDocket.forEach((row) => {
					applySearchExclusionRules(row, coalescedRecords[index], rowsForDocket)
				})

				index++
			})

			search.results = coalescedRecords
			search.rawResultCount = data.length
			search.isSearching = false
			search.hasSearched = true
			search.queryDuration = Date.now() - start
			console.groupEnd()
		},

		updateColumnPresetSelected(preset: string) {
			const visibleColumns: string[] = []

			const basic = ['COURT_CODE', 'COURT_LOCN', 'COURT_NAME', 'DOCKET', 'AOC_LINK', 'DIVISION', 'DEF_LNAME', 'DEF_FNAME', 'DEF_MI', 'DOB', 'AL_LNAME', 'AL_FNAME', 'AL_MI']
			
			if (preset === 'basic') {
				visibleColumns.push(...basic)
			} else if (preset === 'important') {
				visibleColumns.push(...basic)
				visibleColumns.push('CHARGE', 'CHARGE_DESC', 'CHARGE_CLASS', 'CHARGE_CLASS_DESC', 'CHARGE_NUM', 'FILE_DATE', 'PLEA', 'DISP', 'DISP_CHRG_NO', 'DISP_DATE', 'SENTENCE_CODE', 'SENTENCE_LENGTH', 'SENTENCE_UNIT', 'FINE', 'REST', 'CASE_STATUS')
			} else if (preset === 'everything') {
				visibleColumns.push(...this.prospectSearchResultsGridColumns.map((column) => column.field))
			}

			for (const column of this.prospectSearchResultsGridColumns) {
				column.visible = visibleColumns.includes(column.field)
			}
		},

	},
})

function getValidLocalStorageKey(key: string): any {
	const value = localStorage.getItem(key)
	if (value === null || value === "") {
		return null
	}
	return value
}

// export const Store = defineComponent({
// 	setup() {
// 		console.log('store setup()')
// 		const store = useStore()

// 		const authToken = localStorage.getItem('auth-token')
// 		if (authToken) {
// 			axios.defaults.headers.common['auth-token'] = authToken
// 		}
// 		console.log('thangs')
// 		store.getLoggedInUserDetails()

// 		return {
// 			store,
// 		}
// 	},
// })

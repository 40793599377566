
import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import { defineComponent } from "vue"
import { INewUser } from "../lib/InterfacesAndTypes"
import PageHeader from "./PageHeader.vue"
import { VueRouterInst } from "../InitRoutesPlugins"

export default defineComponent({
	name: 'CreateNewUserScreen',
	components: {
		PageHeader,
	},
	data: () => {
		const d: {
			errors: string[],
			isCreating: boolean,
			newUser: INewUser,
		} = {
			errors: [],
			isCreating: false,
			newUser: {
				name: '',
				emailAddress: '',
				password: '',
				passwordConfirm: '',
			},
		}
		return d
	},
	computed: {
		...mapState(useStore, []),
	},
	methods: {
		...mapActions(useStore, ['validateIsEmailAddress', 'submitCreateNewUser']),

		isFormValid() {
			return this.validateIsEmailAddress(this.newUser.emailAddress)
				&& this.validatePasswordsMatch()
				&& this.newUser.name
				&& this.newUser.emailAddress
				&& this.newUser.password
				&& this.newUser.passwordConfirm
		},

		async tryCreate() {
			this.isCreating = true
			const result = await this.submitCreateNewUser(this.newUser)
			if (!result) {
				this.newUser.emailAddress = ''
				this.$buefy.toast.open({
					duration: 8000,
					message: 'There was an error creating the user account, please try again.',
					type: 'is-danger',
				})
			}
			this.isCreating = false
			VueRouterInst.push({ path: '/users/manage' })
		},

		validatePasswordsMatch() {
			return this.newUser.password === this.newUser.passwordConfirm
		},
	},
	// mounted() { },
})

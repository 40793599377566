
import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import { defineComponent } from "vue"
import PageHeader from "./PageHeader.vue"

export default defineComponent({
	name: 'ManageUserAccountsScreen',
	components: {
		PageHeader,
	},
	data: () => {
		return {
			modalOperation: false, // controls spinner/button lockout on modals

			isChangePasswordModalActive: false,
			emailAddressForChangePassword: '',
			newPassword: '',
			newPasswordConfirm: '',

			isDeleteModalActive: false,
			emailAddressForDelete: '',

			columns: [
				{
					field: "userId",
					label: "ID",
				},
				{
					field: "name",
					label: "Name",
					searchable: true,
					sortable: true,
				},
				{
					field: "emailAddress",
					label: "Email",
					searchable: true,
					sortable: true,
				},
				{
					field: "lastLogin",
					label: "Last Login",
					sortable: true,
				},
				{
					// field: "",
					label: "Actions",
				},
			],
		}
	},
	computed: {
		...mapState(useStore, ['userAccounts']),
	},
	methods: {
		...mapActions(useStore, ['fetchUserAccounts', 'changeUserPassword', 'deleteUser']),

		validatePasswordsMatch() {
			return this.newPassword === this.newPasswordConfirm
		},

		startChangeUserPassword(emailAddress: string) {
			this.isChangePasswordModalActive = true
			this.emailAddressForChangePassword = emailAddress
			this.newPassword = ''
			this.newPasswordConfirm = ''
		},
		cancelChangeUserPassword() {
			this.isChangePasswordModalActive = false
		},
		async tryChangeUserPassword(emailAddress: string, password: string) {
			this.modalOperation = true
			const result = await this.changeUserPassword(emailAddress, password)
			console.log('change user complete?')
			this.isChangePasswordModalActive = false
			this.modalOperation = false
		},

		startDeleteUser(emailAddress: string) {
			this.isDeleteModalActive = true
			this.emailAddressForDelete = emailAddress
		},
		cancelDeleteUser() {
			this.isDeleteModalActive = false
		},
		async tryDeleteUser(emailAddress: string) {
			this.modalOperation = true
			const result = await this.deleteUser(emailAddress)
			console.log('delete user complete?')
			this.isDeleteModalActive = false
			this.modalOperation = false
		},
	},
	mounted() {
		this.fetchUserAccounts()
	},
})

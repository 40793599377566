
import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import { defineComponent } from "vue"
import BulkProspectSearchCriteriaGrid from "./BulkProspectSearchCriteriaGrid.vue"
import PageHeader from "./PageHeader.vue"

export default defineComponent({
	name: 'BulkProspectSearchScreen',
	data: () => ({
		isSaving: false,
		isInitial: true,
	}),
	components: {
		BulkProspectSearchCriteriaGrid,
		PageHeader,
	},
	computed: {
		...mapState(useStore, ['bulkProspectSearchCriteria']),
	},
	methods: {
		...mapActions(useStore, ['downloadCSVBulkTemplate', 'parseCSVBulkFile']),
		readFile(eventName: string, inputFiles: any) {
			if(!inputFiles) {
				return
			}
			const inputFile = inputFiles[0]

			console.log('file upload', inputFile)
			const reader = new FileReader()
			const fileExists = !!inputFile

			if (fileExists) {
				reader.onload = ((res: any) => {
					this.parseCSVBulkFile(res.target.result)
				});

				reader.onerror = ((err: any) => { 
					// TODO (KJE, 6/1/2023): when doing UX, include better error handling here
					console.log(err)
				});

				reader.readAsText(inputFile)
			} else {
				// TODO (KJE, 6/1/2023): when doing UX, include better error handling here
				console.log('You must upload a .csv file!')
			}
		},
	},
})

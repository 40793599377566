
import { omit } from "lodash"
import { mapActions, mapState } from "pinia"
import { defineComponent } from "vue"
import { PROSPECT_SEARCH_RESULTS_GRID_COLUMNS } from "../lib/InterfacesAndTypes"
import { useStore } from "../store/PiniaStore"

export default defineComponent({
	props: {
		searchResult: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			clickTimeout: 0,
		}
	},
	computed: {
		...mapState(useStore, ["searchesActive"]),
		columns() { return PROSPECT_SEARCH_RESULTS_GRID_COLUMNS },
		searchResultFieldsStripped() {
			return omit(this.searchResult, ['RAW_RECORDS'])
		},
	},
	methods: {
		...mapActions(useStore, ["onSearchResultsTableCellClick"]),
		onCellClick(row: any, column, rowIndex, columnIndex) {
			this.clickTimeout = this.onSearchResultsTableCellClick(row, column, this.clickTimeout, this.$buefy)
		},
	},
	mounted() {
		console.log('searchResult', this.searchResult)
		console.log('stripped', this.searchResultFieldsStripped)
		console.log(this.columns)
		const actionSeqCol = this.columns.find((col) => col.field ==='ACTION_SEQ')
		if (actionSeqCol) {
			actionSeqCol.visible = true
		}
	},
})

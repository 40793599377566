
import { mapActions, mapState } from "pinia"
import { useStore } from "@/store/PiniaStore"
import { ProspectSearchCriteria, PROSPECT_SEARCH_RESULTS_GRID_COLUMNS } from "@/lib/InterfacesAndTypes"
import ProspectSearchResultsDrilldown from './ProspectSearchResultsDrilldown.vue'
import { defineComponent } from "vue"

export default defineComponent({
	components: { ProspectSearchResultsDrilldown },
	props: {
		search: {
			type: Object as () => ProspectSearchCriteria,
			required: true,
		},
	},
	data: () => {
		return {
			showColumnSelect: false,
			columnPreset: 'Important',
			reportSelectedRows: [] as any[],
			clickTimeout: 0,
		}
	},
	computed: {
		...mapState(useStore, ["searchesActive", "prospectSearchResultsGridColumns", "FABSelectedReportRows"]),
	},
	methods: {
		...mapActions(useStore, ["addSearch", "submitSearch", "closeSearch", "updateColumnPresetSelected", "setFABAction", "clearFABAction", "addReportRow", "removeReportRow", "onSearchResultsTableCellClick"]),

		setGenerateReportFAB(selectedRows: any[]) {
			// console.log('setGenerateReportFAB()')
			const reportRows = selectedRows
			const data = {
				reportSelectedRows: reportRows,
			}
			console.log({
				reportRows,
				data,
			})
			this.setFABAction("generate-report")
		},

		onRowChecked(checkedList: any[], row?: any) {
			// console.log('onRowChecked()', { checkedList, row })
			if (checkedList.includes(row)) {
				// console.log({
				// 	search: this.search,
				// 	row: row,
				// })
				this.addReportRow(row, this.search.fields)
			} else {
				this.removeReportRow(row)
			}
		},

		onAllRowsChecked(checkedList: any[]) {
			console.log('onAllRowsChecked()', { checkedList })
			checkedList.forEach((row) => {
				this.addReportRow(row, this.search.fields)
			})
		},

		findAndCheckSelectedRows() {
			// console.log('findAndCheck')
			for (const selectedRow of this.FABSelectedReportRows) {
				for (const resultRow of this.search.results) {
					if (resultRow.DOCKET === selectedRow.DOCKET && resultRow.ACTION_SEQ === selectedRow.ACTION_SEQ) {
						this.reportSelectedRows.push(resultRow)
					}
				}
			}
		},

		onCellClick(row: any, column, rowIndex, columnIndex) {
			this.clickTimeout = this.onSearchResultsTableCellClick(row, column, this.clickTimeout, this.$buefy)
		},
	},

	watch: {
		'search.results': function(newVal, oldVal) {
			// console.log('search changed?', newVal, oldVal)
			this.findAndCheckSelectedRows()
		},
	},

	mounted(): void {
		console.log(`ProspectSearchResultsGrid mounted`)
		this.findAndCheckSelectedRows()
	},
})

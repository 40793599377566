
import { mapActions, mapState } from "pinia"
import { useStore } from "@/store/PiniaStore"
import ProspectSearchResultsGrid from './ProspectSearchResultsGrid.vue'
import PageHeader from "./PageHeader.vue"
import { defineComponent } from "vue"

export default defineComponent({
	components: {
		ProspectSearchResultsGrid,
		PageHeader,
	},
	props: {
	},
	computed: {
		...mapState(useStore, ["FABSelectedReportRows", "FABSelectedReportRowsAsSearchGrid"]),
	},
	methods: {
		...mapActions(useStore, ["setFABShowCancel"]),
	},

	mounted(): void {
		console.log(`ReviewReportScreen mounted`)
		this.setFABShowCancel(true)
	},
	beforeRouteLeave(to, from, next) {
		this.setFABShowCancel(false)
		next()
	},
})

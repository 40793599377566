
export const ENVIRONMENT = {
	local: {
		aggregator: {
			url: 'http://localhost:8081/',
		},
	},
	staging: {
		aggregator: {
			url: 'https://aggregator.apps.wil-max.com/',
		},
	},
	production: {
		aggregator: {
			url: 'https://aggregator.apps.wil-max.com/',
		},
	},
}

export type EnvironmentConfig = typeof ENVIRONMENT.local
export type EnvironmentKeys = keyof typeof ENVIRONMENT

let CURRENT_ENVIRONMENT: EnvironmentConfig

export function GET_ENV(): EnvironmentConfig {
	if (!CURRENT_ENVIRONMENT) {
		const envString: EnvironmentKeys = process.env.NODE_ENV || "local"
		if (ENVIRONMENT[envString] !== undefined) {
			CURRENT_ENVIRONMENT = ENVIRONMENT[envString]
		}

		if (!CURRENT_ENVIRONMENT) {
			throw new Error(`NODE_ENV [${process.env.NODE_ENV}] was not a valid environment. Valid env options: ${Object.keys(ENVIRONMENT)}`)
		}

		console.log(`CURRENT_ENVIRONMENT: ${envString}`)
	}

	return CURRENT_ENVIRONMENT
}

import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import { defineComponent } from "vue"
import PageHeader from "./PageHeader.vue"
import { curry } from "lodash"

export default defineComponent({
	name: 'DataAuditScreen',
	components: {
		PageHeader,
	},
	data: () => {
		return {
			columns: [
				{
					field: "jobId",
					label: "ID",
				},
				{
					field: "jobType",
					label: "Job",
					searchable: true,
					customSearch: customSearch.bind(null, 'jobType'),
					sortable: true,
				},
				{
					field: "jobSubtype",
					label: "Subtype",
					searchable: true,
					customSearch: customSearch.bind(null, 'jobSubtype'),
					sortable: true,
				},
				{
					field: "jobSummary",
					label: "Summary",
					searchable: true,
					customSearch: customSearch.bind(null, 'jobSummary'),
					sortable: true,
				},
				{
					field: "createdAt",
					label: "Date",
					searchable: true,
					sortable: true,
				},
			],
		}
	},
	computed: {
		...mapState(useStore, ['dataAuditRecords']),
	},
	methods: {
		...mapActions(useStore, ['fetchDataAuditRecords']),
	},
	mounted() {
		this.fetchDataAuditRecords()
	},
})

function customSearch(key: string, a: any, input: string) {
	if (input.startsWith('!')) {
		const arg = input.slice(1)
		return !a[key].startsWith(arg)
	}
	return a[key].startsWith(input)
}

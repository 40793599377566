export function applySearchExclusionRules(row, coalescedRecord, rawRecords: any[]) {
	if (row.RECORD_ACTION === 'CSR' || row.RECORD_ACTION === 'CSL' || row.RECORD_ACTION === 'PSL' || row.RECORD_ACTION === 'PSR') {
		coalescedRecord.__recordIsSealed = true
		coalescedRecord.__recordSealedReason = `ACTION_SEQ ${row.ACTION_SEQ} is ${row.RECORD_ACTION}`
	}
}

export function findIndexOfFirstIncludedValue(arrayOfThings: string[], possibleValues: string[]) {
	return arrayOfThings.indexOf(
		possibleValues.find((candidate) => { 
			return arrayOfThings.includes(candidate.toLowerCase())
		}) || "notfound")
}

export function isStringEmpty(str: string): boolean {
	return (!str || str.length === 0)
}

export function isStringEmptyOrWhitespace(str: string): boolean {
	return (isStringEmpty(str) || str.trim().length === 0)
}

export function isStringDictionaryEmptyOrWhitespace(obj: Object): boolean {
	return (!obj || !Object.values(obj) || Object.values(obj).filter((val) => {
		return typeof val === 'string' && !isStringEmptyOrWhitespace(<string>val)
	}).length === 0)
}

export function stripNull(obj) {
	return Object.entries(obj)
		.filter(([_, v]) => v !== null)
		.reduce((acc, [k, v]) => ({ ...acc, [k]: v}), {})
}

export function stripUndefined(obj) {
	return Object.entries(obj)
		.filter(([_, v]) => v !== undefined)
		.reduce((acc, [k, v]) => ({ ...acc, [k]: v}), {})
}

export function stripNullish(obj) {
	return Object.entries(obj)
		.filter(([_, v]) => v != null)
		.reduce((acc, [k, v]) => ({ ...acc, [k]: v}), {})
}

export function stripNullAndUndefined(obj) {
	return stripUndefined(stripNull(obj))
}

export function setPageTitle(title: string, subtitle?: string) {
	document.title = `${title}${subtitle ? ' - ' + subtitle : ''} | Wil-Max Case Search`
}

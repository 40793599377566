
import { mapActions, mapState } from "pinia"
import { useStore } from "@/store/PiniaStore"
import { ProspectSearchCriteria } from "@/lib/InterfacesAndTypes"
import { defineComponent } from "vue"

export default defineComponent({
	components: { }, // I don't know why tf this is throwing an error in my VScode, but it doesn't show up at compile
	props: {
		search: {
			type: Object as () => ProspectSearchCriteria,
			required: true,
		},
	},
	computed: {
		...mapState(useStore, ["searchesActive"]),
	},
	methods: {
		...mapActions(useStore, ["addSearch", "submitSearch", "closeSearch"]),
	},
})

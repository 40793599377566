const COLORS = [
	'#EEEEF7',
	'#FAEEEA',
	'#F7FAEA',
	'#EAF3FA',
	'#FDF7F9',
]
let COLOR_INDEX = 0

export const BULK_SEARCH_COLORS = {
	Error: '#FF3333',
	Results: '#33FF33',
	Empty: '#FF33FF',
	Pending: '#FFFFFF',
}

export type FABActionType = "generate-report" | "download-report" | null

class CaseRecord { }

export class ProspectSearchCriteria {
	fields: {
		firstName?: string
		middleName?: string
		lastName?: string
		dateOfBirth?: string
		dateOfBirthNullCheck?: boolean
		courtCode?: string
		locationCode?: string
		division?: string
		caseId?: string
	} = {
		firstName: undefined,
		middleName: undefined,
		lastName: undefined,
		dateOfBirth: undefined,
		dateOfBirthNullCheck: false,
		courtCode: undefined,
		locationCode: undefined,
		division: undefined,
		caseId: undefined,
	}

	get firstName() { return this.fields.firstName }
	get middleName() { return this.fields.middleName }
	get lastName() { return this.fields.lastName }
	get dateOfBirth() { return this.fields.dateOfBirth }
	get dateOfBirthNullCheck() { return this.fields.dateOfBirthNullCheck }
	get courtCode() { return this.fields.courtCode }
	get locationCode() { return this.fields.locationCode }
	get division() { return this.fields.division }
	get caseId() { return this.fields.caseId }

	color?: string
	bulkSearchColor?: string

	isSearching = false
	hasErrors: false | { message: any, status: any, statusText: any } = false
	hasSearched = false
	queryDuration: number = 0
	rawResultCount: number = 0
	results: any[] = []
	get hasResults(): boolean {
		return !!this.results.length
	}

	constructor() {
		this.color = COLORS[COLOR_INDEX]
		COLOR_INDEX = (COLOR_INDEX + 1) % COLORS.length

		this.bulkSearchColor = BULK_SEARCH_COLORS['Pending']
	}
}

export type ProspectSearchFields = typeof ProspectSearchCriteria.prototype.fields

export const CaseRecordFieldGroups = {
	Charge: [
		'CHARGE',
		'CHARGE_CLASS',
		'CHARGE_VIO_DATE',
		'CHARGE_NUM',
		'CHARGE_DESC',
	],
	Disposition: [
		'DISP',
		'DISP_CHRG_NO',
		'DISP_DATE',
	],
	Location: [
		'COURT_CODE',
		'COURT_LOCN',
		'DIVISION',
	],
	Sentence: [
		'SENTENCE_CODE',
		'SENTENCE_LENGTH',
		'SENTENCE_UNIT',
		'SENTENCE_CHRG_NO',
		'SENTENCE_CONCURRENT',
		'SENTENCE_CONSECUTIVE',
		'SENTENCE_SUSP',
		'SENTENCE_SUSP_UNIT',
	],
	Name: [
		'DEF_LNAME',
		'DEF_FNAME',
		'DEF_MI',
	],
	Alias: [
		'AL_LNAME',
		'AL_FNAME',
		'AL_MI',
	],
	Identity: [
		'RACE',
		'SEX',
		'DOB',
	],
	
}

export interface INewUser {
	name: string
	emailAddress: string
	password: string
	passwordConfirm: string
}

export const BULK_SEARCH_HEADER_CANDIDATES = {
	firstName: ['first name', 'firstname', 'fname'],
	middleName: ['middle name', 'middlename', 'mi', 'mname', 'middle initial'],
	lastName: ['last name', 'lastname', 'lname'],
	dateOfBirth: ['dateofbirth', 'date of birth', 'dob', 'birthday', 'birthdate', 'birth date'],
	courtCode: ['court code', 'courtcode', 'jurisdiction'],
	locationCode: ['location code', 'locationcode'],
	division: ['division', 'div'],
	caseId: ['caseid', 'case id', 'docket', 'docket number', 'docketnr'],
}

export const PROSPECT_SEARCH_RESULTS_GRID_COLUMNS = [
	{
		field: "ACTION_SEQ",
		label: "ACTION_SEQ",
		sortable: true,
		visible: true,
	},
	{
		field: "REC_TYPE",
		label: "REC_TYPE",
		visible: false,
	},
	{
		field: "UPDATE_SW",
		label: "UPDATE_SW",
		visible: false,
	},
	{
		field: "TRANS_DT",
		label: "TRANS_DT",
		visible: false,
	},
	{
		field: "COURT_CODE",
		label: "COURT_CODE",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "COURT_NAME",
		label: "COURT_NAME",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "COURT_LOCN",
		label: "COURT_LOCN",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "DOCKET",
		label: "DOCKET",
		visible: true,
		searchable: true,
		sortable: true,
		headerClass: 'table-column-docket',
		cellClass: 'monospace code-block code-gray',
	},
	{
		field: "AOC_LINK",
		label: "AOC",
		visible: true,
		renderHtml: true,
	},
	{
		field: "DIVISION",
		label: "DIVISION",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "DEF_LNAME",
		label: "DEF_LNAME",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "DEF_FNAME",
		label: "DEF_FNAME",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "DEF_MI",
		label: "DEF_MI",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "DOB",
		label: "DOB",
		headerClass: 'table-column-seg-number',
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "CHARGE",
		label: "CHARGE",
		headerClass: 'table-column-seg-number',
		visible: false,
	},
	{
		field: "CHARGE_DESC",
		label: "CH_DESC",
		headerClass: 'table-column-desc',
		visible: false,
	},
	{
		field: "CHARGE_CLASS",
		label: "CH_CLASS",
		visible: false,
	},
	{
		field: "CHARGE_CLASS_DESC",
		label: "CH_CLASS_DESC",
	},
	{
		field: "CHARGE_VIO_DATE",
		label: "CH_VIO_DATE",
		visible: false,
	},
	{
		field: "CHARGE_NUM",
		label: "CH_NUM",
		visible: false,
		sortable: true,
	},
	// {
	// 	field: "INCHOATE",
	// 	label: "INCHOATE",
	// },
	// {
	// 	field: "ATN",
	// 	label: "ATN",
	// },
	// {
	// 	field: "CSN",
	// 	label: "CSN",
	// },
	// {
	// 	field: "SID",
	// 	label: "SID",
	// },
	{
		field: "ARREST_DATE",
		label: "ARREST_DATE",
		visible: false,
	},
	{
		field: "FILE_DATE",
		label: "FILE_DATE",
		visible: false,
	},
	{
		field: "AL_LNAME",
		label: "AL_LNAME",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "AL_FNAME",
		label: "AL_FNAME",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "AL_MI",
		label: "AL_MI",
		visible: true,
		searchable: true,
		sortable: true,
	},
	{
		field: "RACE",
		label: "RACE",
		visible: false,
	},
	{
		field: "SEX",
		label: "SEX",
		visible: false,
		searchable: true,
		sortable: true,
	},
	{
		field: "WARRANT_STATUS",
		label: "WARRANT_STATUS",
		visible: false,
	},
	// {
	// 	field: "TRIAL_TYPE",
	// 	label: "TRIAL_TYPE",
	// },
	// {
	// 	field: "TYPE_CNSL",
	// 	label: "TYPE_CNSL",
	// },
	{
		field: "PLEA",
		label: "PLEA",
		visible: false,
	},
	{
		field: "PLEA_CHRG_NO",
		label: "PLEA_CHRG_NO",
		visible: false,
	},
	{
		field: "PLEA_DATE",
		label: "PLEA_DATE",
		visible: false,
	},
	{
		field: "DISP",
		label: "DISP",
		visible: false,
	},
	{
		field: "DISP_CHRG_NO",
		label: "DISP_CHRG_NO",
		visible: false,
	},
	{
		field: "DISP_DATE",
		label: "DISP_DATE",
		visible: false,
		sortable: true,
	},
	{
		field: "SENTENCE_CODE",
		label: "SENTENCE_CODE",
		visible: false,
	},
	{
		field: "SENTENCE_LENGTH",
		label: "SENT_LENGTH",
		visible: false,
	},
	{
		field: "SENTENCE_UNIT",
		label: "SENT_UNIT",
		visible: false,
	},
	{
		field: "SENTENCE_CHRG_NO",
		label: "SENT_CHRG_NO",
		visible: false,
	},
	{
		field: "SENTENCE_CONCURRENT",
		label: "SENT_CONCURRENT",
		visible: false,
	},
	{
		field: "SENTENCE_CONSECUTIVE",
		label: "SENT_CONSECUTIVE",
		visible: false,
	},
	{
		field: "SENTENCE_SUSP",
		label: "SENT_SUSP",
		visible: false,
	},
	{
		field: "SENTENCE_SUSP_UNIT",
		label: "SENT_SUSP_UNIT",
		visible: false,
	},
	{
		field: "COND_CODE",
		label: "COND_CODE",
		visible: false,
	},
	{
		field: "COND_UNIT",
		label: "COND_UNIT",
		visible: false,
	},
	{
		field: "COND_LENGTH",
		label: "COND_LENGTH",
		visible: false,
	},
	{
		field: "FINE",
		label: "FINE",
		visible: false,
	},
	{
		field: "REST",
		label: "REST",
		visible: false,
	},
	{
		field: "OFFTYPE",
		label: "OFFTYPE",
		visible: false,
	},
	{
		field: "PUB_SERVICE",
		label: "PUB_SERVICE",
		visible: false,
	},
	{
		field: "NO_OF_ADD_ATNS",
		label: "NO_OF_ADD_ATNS",
		visible: false,
	},
	{
		field: "ADD_ATNS",
		label: "ADD_ATNS",
		visible: false,
	},
	{
		field: "NO_OF_ADD_CSNS",
		label: "NO_OF_ADD_CSNS",
		visible: false,
	},
	{
		field: "ADD_CSNS",
		label: "ADD_CSNS",
		visible: false,
	},
	{
		field: "NO_OF_ADD_CONDS",
		label: "NO_OF_ADD_CONDS",
		visible: false,
	},
	{
		field: "ADD_CONDS",
		label: "ADD_CONDS",
		visible: false,
	},
	// {
	// 	field: "EVENT_CODE",
	// 	label: "EVENT_CODE",
	// },
	// {
	// 	field: "EVENT_TYPE",
	// 	label: "EVENT_TYPE",
	// },
	// {
	// 	field: "EVENT_ID",
	// 	label: "EVENT_ID",
	// },
	// {
	// 	field: "EVENT_DATE",
	// 	label: "EVENT_DATE",
	// },
	// {
	// 	field: "EVENT_TIME",
	// 	label: "EVENT_TIME",
	// },
	// {
	// 	field: "EVENT_STATUS",
	// 	label: "EVENT_STATUS",
	// },
	// {
	// 	field: "CONTEXTE_USER_ID",
	// 	label: "CONTEXTE_USER_ID",
	// },
	{
		field: "CASE_STATUS",
		label: "CASE_STATUS",
		visible: false,
	},
	{
		field: "RECORD_ACTION",
		label: "RECORD_ACTION",
	},
	// {
	// 	field: "DEFENDANT_ID",
	// 	label: "DEFENDANT_ID",
	// },
	{
		field: "CASE_TYPE",
		label: "CASE_TYPE",
	},
	// {
	// 	field: "UNIQUE_LOCATION",
	// 	label: "UNIQUE_LOCATION",
	// },
	{
		field: "COURT_DESC",
		label: "COURT_DESC",
	},
	{
		field: "LOCATION_DESC",
		label: "LOCATION_DESC",
	},
	// {
	// 	field: "JUDGE_NAME",
	// 	label: "JUDGE_NAME",
	// },
	// {
	// 	field: "VIOLATION_FILE_DATE",
	// 	label: "VIOLATION_FILE_DATE",
	// },
	// {
	// 	field: "SSN",
	// 	label: "SSN",
	// },
	// {
	// 	field: "DRIVER_LICENSE_NO",
	// 	label: "DRIVER_LICENSE_NO",
	// },
	// {
	// 	field: "DRIVER_LICENSE_STATE",
	// 	label: "DRIVER_LICENSE_STATE",
	// },
	// {
	// 	field: "DEF_ZIP",
	// 	label: "DEF_ZIP",
	// },
	// {
	// 	field: "RACE_DESC",
	// 	label: "RACE_DESC",
	// },
	// {
	// 	field: "ATTNY_NAME",
	// 	label: "ATTNY_NAME",
	// },
	{
		field: "PLEA_DESC",
		label: "PLEA_DESC",
	},
	{
		field: "DISP_DESC",
		label: "DISP_DESC",
	},
	{
		field: "SENTENCE_DESC",
		label: "SENTENCE_DESC",
	},
	// {
	// 	field: "CONDITION_DESC",
	// 	label: "CONDITION_DESC",
	// },
	{
		field: "EVENT_DESC",
		label: "EVENT_DESC",
	},
	{
		field: "CASE_TYPE_DESC",
		label: "CASE_TYPE_DESC",
	},
	// {
	// 	field: "EXPANSION1",
	// 	label: "EXPANSION1",
	// },
	// {
	// 	field: "EXPANSION2",
	// 	label: "EXPANSION2",
	// },
	// {
	// 	field: "EXPANSION3",
	// 	label: "EXPANSION3",
	// },
	// {
	// 	field: "DL_TYPE_DESC",
	// 	label: "DL_TYPE_DESC",
	// },
	{
		field: "TYPE_OF_CASE",
		label: "TYPE_OF_CASE",
	},
]


import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import axios from 'axios'
import 'buefy/dist/buefy.css'
import { createPinia, PiniaVuePlugin } from 'pinia'
import './util/StandardLibrary.ts'
import { useStore } from '@/store/PiniaStore'
import VueRouter from 'vue-router'
import ProspectSearchScreen from './components/ProspectSearchScreen.vue'
import BulkProspectSearchScreen from './components/BulkProspectSearchScreen.vue'
import DataAuditScreen from './components/DataAuditScreen.vue'
import ManageUserAccountsScreen from './components/ManageUserAccountsScreen.vue'
import CreateNewUserScreen from './components/CreateNewUserScreen.vue'
import LoginScreen from './components/LoginScreen.vue'
import ReviewReportScreen from './components/ReviewReportScreen.vue'
import { GET_ENV } from '@/config'
import { setPageTitle } from '@/lib/HelperFns'

let VueAppInst: Vue
export let VueRouterInst: VueRouter

export async function initRoutesAndPlugins() {
	Vue.config.productionTip = false


	// ghetto hack to disable dumb functionality
	const originalPush = VueRouter.prototype.push
	VueRouter.prototype.push = function push(location) {
		return (originalPush.call(this, location) as any).catch((err: { name: string }) => {
		if (err.name !== "NavigationDuplicated") {
			throw err
		}
	})
}

	Vue.use(VueRouter)
	Vue.use(PiniaVuePlugin)
	Vue.use(Buefy)

	VueRouterInst = new VueRouter({
		routes: [
			{ path: '/', component: LoginScreen, name: 'Login' },
			{ path: '/login', component: LoginScreen, name: 'Login' },
			{ path: '/search', component: ProspectSearchScreen, name: 'Prospect Search' },
			{ path: '/bulk-search', component: BulkProspectSearchScreen, name: 'Bulk Search' },
			{ path: '/data-audit', component: DataAuditScreen, name: 'Data Audit' },
			{ path: '/users/manage', component: ManageUserAccountsScreen, name: 'Manage Users' },
			{ path: '/users/create', component: CreateNewUserScreen, name: 'Create User' },
			{ path: '/review-report', component: ReviewReportScreen, name: 'Review Report' },
		],
		scrollBehavior: (to, from, savedPosition) => {
			return { selector: 'body', top: 0 }
		},
	})
	VueRouterInst.afterEach((to, _from) => {
		Vue.nextTick(() => {
			setPageTitle(to.name ?? '')
		})
	})

	const pinia = createPinia()

	axios.defaults.baseURL = GET_ENV().aggregator.url //TODO: probably change this at some point, if we ever add other servers we need to query/external integrations
	axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
	axios.defaults.timeout = 90000

	const authToken = localStorage.getItem('auth-token')
	if (authToken) {
		axios.defaults.headers.common['auth-token'] = authToken
		await useStore(pinia).getLoggedInUserDetails()
		await useStore(pinia).getKeyValueRecords()
	}

	VueAppInst = new Vue({
		render: h => h(App),
		router: VueRouterInst,
		pinia,
	}).$mount('#app')
}


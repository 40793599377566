interface Array<T> {
	remove(o: T): T[]
	removeAll(o: T): T[]
	pickRandom(): T
	first(): T
}

interface Math {
	clamp(num: number, lowerBound?: number, upperBound?: number): number
	lerp(a: number, b: number, t: number): number
}

interface String {
	toProperCase(): string
	commafy(): string
}

interface Number {
	between(lowerBound: number, upperBound: number, exclusive?: boolean): boolean
}

Array.prototype.remove = function(thingToRemove) {
	const indexOfItemToRemove = this.indexOf(thingToRemove)
	if (indexOfItemToRemove > -1) {
		return this.splice(indexOfItemToRemove, 1)
	} else {
		return this
	}
}

Array.prototype.removeAll = function(thingToRemove) {
	let indexOfItemToRemove = this.indexOf(thingToRemove)
	while (indexOfItemToRemove > -1) {
		this.splice(indexOfItemToRemove, 1)
		indexOfItemToRemove = this.indexOf(thingToRemove)
	}
	return this
}

Array.prototype.pickRandom = function() {
	const idx = Math.floor(Math.random() * this.length)
	if (idx >= this.length) {
		throw new Error(`bad index ${idx} >= array length`)
	}
	return this[idx]
}

Array.prototype.first = function() {
	return this.length ? this[0] : undefined
}

Math.clamp = function(num, lowerBound = 0, upperBound = 1): number {
	return Math.min(upperBound, Math.max(num, lowerBound))
}

Math.lerp = function(a, b, t): number {
	return a * (1.0 - t) + b * t
}

Number.prototype.between = function(lowerBound: number, upperBound: number, exclusive?: boolean): boolean {
	if (exclusive) {
		return this > lowerBound && this < upperBound
	}
	return this >= lowerBound && this <= upperBound
}

String.prototype.commafy = function() {
	return this.replace(/(^|[^\w.])(\d{4,})/g, function($0, $1, $2) {
		return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, '$&,')
	})
}

String.prototype.toProperCase = function() {
	return this.replace(/\w\S*/g, function(frag: string) {
		return frag.charAt(0).toUpperCase() + frag.substr(1).toLowerCase()
	})
}



import { mapActions, mapState } from "pinia"
import { useStore } from "@/store/PiniaStore"
import { ProspectSearchCriteria } from "@/lib/InterfacesAndTypes"
import ProspectSearchResultsGrid from './ProspectSearchResultsGrid.vue'
import { defineComponent } from "vue"

export default defineComponent({
	components: { ProspectSearchResultsGrid },
	props: {
		search: {
			type: Object as () => ProspectSearchCriteria,
			required: true,
		},
	},
	computed: {
		...mapState(useStore, ["searchesActive"]),
	},
	methods: {
		...mapActions(useStore, ["addSearch", "submitSearch", "closeSearch"]),

		setFocus(fieldNum: number = 1) {
			const elem: any = this.$refs[`field${fieldNum}`]
			if (elem) {
				elem.focus()
			}
		},
	},

	mounted(): void {
		console.log(`ProspectSearchCard mounted`)
	},
})


import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import { defineComponent } from "vue"
import { VueRouterInst } from '../InitRoutesPlugins'

export default defineComponent({
	name: 'MainUI',
	components: { },
	data: () => {
		const d: {
			isWhatsNewModalActive: boolean
		} = {
			isWhatsNewModalActive: false,
		}
		return d
	},
	computed: {
		...mapState(useStore, ['searchesActive', 'userManagement', 'isLoggedIn', 'FABActionType', 'FABSelectedReportRows', 'FABSelectedReportRowCount', 'FABShowCancel']),
	},
	methods: {
		...mapActions(useStore, ['addEmptySearch', 'submitLogout', 'updateColumnPresetSelected', 'doFABAction', 'clearFABAction']),
	},
	mounted(): void {
		if (!this.isLoggedIn) {
			if (VueRouterInst.currentRoute.path !== '/login') {
				console.log('Not logged in, redirecting to /login')
				VueRouterInst.push({ path: '/login', replace: true })
			}
		}
		this.updateColumnPresetSelected('important')
	},

})

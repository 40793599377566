
import { mapActions, mapState } from "pinia"
import { useStore } from "../store/PiniaStore"
import ProspectSearchCard from "./ProspectSearchCard.vue"
import ProspectSearchSummaryCard from "./ProspectSearchSummaryCard.vue"
import { defineComponent } from "vue"
import PageHeader from "./PageHeader.vue"

export default defineComponent({
	name: 'ProspectSearchScreen',
	components: {
		ProspectSearchCard,
		ProspectSearchSummaryCard,
		PageHeader,
	},
	computed: {
		...mapState(useStore, ['searchesActive']),
	},
	methods: {
		...mapActions(useStore, ['addEmptySearch', 'submitSearch', 'updateSearchTitle']),

		addEmptySearchWrapped() {
			this.addEmptySearch()
			const i = this.searchesActive.length - 1
			this.scrollToSearchCard(i, true)
		},

		scrollToSearchCard(i: number, focus: boolean = false) {
			this.$nextTick(() => {
				const elems: any[] = this.$refs[`search${i}`] as any[]
				if (elems.length) {
					const component = elems[0]
					const elem: HTMLElement = component.$el
					const top = elem.offsetTop
					window.scrollTo({ left: 0, top, behavior: "smooth" })
					if (focus) {
						setTimeout(() => { //TODO: probably ghetto
							component.setFocus(1)
						}, 250)
					}
				}
			})
		},
	},

	mounted() {
		if (!this.searchesActive.length) {
			this.addEmptySearchWrapped()
		}
		this.updateSearchTitle()
	},
})


import { mapActions, mapState } from "pinia"
import { useStore } from "@/store/PiniaStore"
import { ProspectSearchCriteria } from "@/lib/InterfacesAndTypes"
import ProspectSearchResultsGrid from "./ProspectSearchResultsGrid.vue"
import { defineComponent } from "vue"

export default defineComponent({
	components: {
		ProspectSearchResultsGrid,
	},
	data: () => {
		return {
			displayData: [] as ProspectSearchCriteria[],
			isLoading: false,
			globalDateOfBirthNullCheck: false,
			searchRunning: false,
			sortField: '',
			sortOrder: '',
			page: 1,
			perPage: 15,
			clickTimeout: 0,
		}
	},
	computed: {
		...mapState(useStore, ["bulkProspectSearchCriteria", "bulkProspectSearchProgress"]),
		totalProspects() {
			return this.bulkProspectSearchCriteria.length
		},
		displayedData() {
			if (this.searchRunning) {
				if (this.isLoading && this.bulkProspectSearchProgress === this.totalProspects) {
					this.isLoading = false
					this.onSort('resultCount', 'desc')
					this.resetView()
				}
				return this.displayData
			} else {
				if (this.displayData.length > 0) {
					return this.displayData
				} else if (this.displayData.length === 0 && this.totalProspects > 0) {
					this.resetView(this.page)
					return this.displayData
				} else {
					return []
				}
			}
		},
		calculateProgress() {
			if (!this.isLoading) {
				return 0
			} else {
				const prog = Math.floor(this.bulkProspectSearchProgress / this.totalProspects * 100)
				this.updateProgressBar(prog)
				return prog
			}
		},
	},
	methods: {
		...mapActions(useStore, ["runEntireBulkProspectSearch", "onSearchResultsTableCellClick"]),
		updateProgressBar(progress: number) {
			this.$nextTick(() => {
				const element = document.getElementsByClassName('progress-bar')[0] as HTMLProgressElement
				if (!!element) {
					element.setAttribute("value", `${progress}`);
				}
			})
		},
		resetView(page = 1) {
			this.page = page
			try {
				this.displayData = this.bulkProspectSearchCriteria.slice((this.page - 1) * this.perPage, Math.min(this.page * this.perPage, this.bulkProspectSearchCriteria.length))
			} catch (error: any) {
				console.error(error)
				this.displayData = []
			}
		},
		onPageChange(page) {
			this.page = page
			this.resetView(this.page)
		},
		onSort(field, order) {
			this.sortField = field
			this.sortOrder = order

			const columnSortCompare = (a, b) => {
				return (a.fields[this.sortField] || '').localeCompare(b.fields[this.sortField] || '')
			}

			const columnSortCompareResults = (a, b) => {
				return a.results.length - b.results.length
			}

			if (this.sortField === "resultCount") {
				this.bulkProspectSearchCriteria.sort(columnSortCompareResults)
			} else {
				this.bulkProspectSearchCriteria.sort(columnSortCompare)
			}

			if (this.sortOrder === 'desc') {
				this.bulkProspectSearchCriteria.reverse()
			}

			this.resetView()
		},
		runSearch() {
			this.searchRunning = true
			this.isLoading = true
			this.bulkProspectSearchCriteria.forEach((crit) => {
				crit.fields.dateOfBirthNullCheck = this.globalDateOfBirthNullCheck
			})
			this.runEntireBulkProspectSearch()  // This merely starts the searching process
		},

		onCellClick(row: any, column, rowIndex, columnIndex) {
			this.clickTimeout = this.onSearchResultsTableCellClick(row, column, this.clickTimeout, this.$buefy)
		},
	},

	mounted(): void {
		console.log(`BulkProspectSearchCriteriaGrid mounted`)
	},
})
